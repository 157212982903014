import { useApi } from '../modules/api'

export const workerSearch = async (value, limit = 5) => {
	if (value === '' || value) {
		const { get, data } = useApi(`/worker/search?limit=${limit}&search=${value}`)

		await get()

		const map = {}

		data.value.forEach(({ id, surname, name, middleName }) => {
			map[id] = `${surname} ${name} ${middleName}`
		})

		return map
	}
}
